import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { ListMainCommandsPage } from "../features/commands/pages/list-commands/ListMainCommandsPage";
import { ModuleContentContainer } from "./content/ModuleContentContainer";
import { NavBar } from "../lib/components/nav-bar/NavBar";
import { ViewCommandPage } from "../features/commands/pages/view-command/ViewCommandPage";

export function AppRoutingModule() {

    return (
        <>
            <NavBar />

            <ModuleContentContainer>
                <Routes>
                    <Route path="commands" element={<ListMainCommandsPage />} />
                    <Route path="commands/:commandId/*" element={<ViewCommandPage />} />
                    <Route path="*" element={<Navigate to="/notfound" />} />
                </Routes>
            </ModuleContentContainer>

        </>
    );
}
