import "./AppPortal.css";

import { useRenderNotificationContainer, useRenderPopup } from "../../infrastructure/ui/UIServices";

import { NotificationContainer } from "../../../features/commands/components/notifications/NotificationContainer";
import { PopupArea } from '../containers/popup-area/PopupArea';

export interface IAppPortalProps {
    children: React.ReactNode;
}


export function AppPortal(props: IAppPortalProps) {
    const { showPopup, popupContent } = useRenderPopup();
    const { showNotificationContainer } = useRenderNotificationContainer();

    return (
        <div className="app-portal">
            {props.children}

            {showPopup ? <PopupArea>{popupContent}</PopupArea> : null}
            {showNotificationContainer ? <NotificationContainer /> : null}
        </div>
    )
}
