import "./IconButton.css";

import { useCallback, useMemo } from "react";

import { CssClassNameBuilder } from "../../utils/CssClassnameBuilder";

export interface IIconButtonProps {
    size?: number;
    icon: React.ReactNode;
    type: "primary" | "secondary" | "tertiary";
    isRound?: boolean;
    isDisabled?: boolean;
    className?: string;
    isActive?: boolean;
    onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function IconButton(props: IIconButtonProps) {
    const sizeCss = useMemo(() => {
        if (props.size) {
            const baseFontSize = parseFloat(
                window.getComputedStyle(document.documentElement).fontSize
            );
            return {
                width: props.size / baseFontSize + "rem",
                height: props.size / baseFontSize + "rem",
            };
        }
    }, [props.size]);

    const buttonCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("icon-button")
            .addConditional(props.type, props.type)
            .addConditional(props.isRound, "round")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(props.isActive, "selected")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className, props.type, props.isDisabled, props.isRound, props.isActive]);

    const handleClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            if (props.isDisabled) return;
            props.onClick && props.onClick(ev);
        },
        [props.onClick, props.isDisabled]
    );

    return (
        <div
            className={buttonCss}
            style={sizeCss}
            onClick={props.onClick ? handleClicked : () => { }}
        >
            {props.icon}
        </div>
    );
}
