import "./AddCommandPopup.css";

import { requiredValidator, useFormControl } from "../../../../lib/components/form/Form";
import { useCallback, useMemo } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { CommandCreateDTO } from "../../models/serviceDtos/CreateCommandDTO";
import { CommandService } from "../../services/CommandService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { useServiceCallPro } from "../../utils/UseServiceCall";
import { useNavigate } from "react-router-dom";

interface IProps {
    parentCommandId?: number;
    onCompleted: () => void;
}

const svc = new CommandService();


export function AddCommandPopup(props: IProps) {
    const navigate = useNavigate();
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const nameFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true });
    const descriptionFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true });
    //const behaviorFormControl = useFormControl<string>({ validators: [], enableAutoValidate: true });


    /****************************
     * DATA REQUESTS
     *****************************/

    const createCommandCall = useServiceCallPro(svc.createCommand);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    const isButtonDisabled = useMemo(() => {
        return createCommandCall.isLoading;
    }, [createCommandCall.isLoading]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleCreateClicked = useCallback(() => {

        if (!nameFormControl.validate() || !descriptionFormControl.validate())
            return;

        var requestDTO: CommandCreateDTO = {
            name: nameFormControl.value,
            description: descriptionFormControl.value,
            //behavior: behaviorFormControl.value ?? "",
            parameterCreateDTOs: [],
        };

        if(props.parentCommandId) requestDTO = {...requestDTO, parentCommandId: props.parentCommandId}

        createCommandCall.invoke(requestDTO).then((newCommand) => {
            // openPopup(<SuccessPopup>You have created a Space successfully with ID: {newCommand.commandId}</SuccessPopup>);
            //props.onCompleted();
            closePopup();
            navigate(`/commands/${newCommand.commandId}`);
        }).catch((err) => {
            openPopup(<ErrorPopup>{err.message}</ErrorPopup>);
        });
    },[createCommandCall,nameFormControl, descriptionFormControl, openPopup]);

    /****************************
    * CSS & HTML
    *****************************/

    if (createCommandCall.isLoading) {
        return (
            <PopupContainer className="add-space-popup">
                <Loader />
            </PopupContainer>
        )
    }

    return (
        <PopupContainer className="add-space-popup">

            <h3>Add Command</h3>
            <Spacer mode="vertical" px="16" />

            <FormContainer className="inputs">
                <FormFieldTextInput label="Name" placeholder="Insert a name" formControl={nameFormControl} />
                <FormFieldTextInput label="Description" placeholder="Insert a description" formControl={descriptionFormControl} />
                {/* <FormFieldTextArea label="Behavior" placeholder="Insert a Behavior" formControl={behaviorFormControl} /> */}
            </FormContainer>

            <Spacer mode="vertical" px="16" />
            <LineSeparator />

            <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >

                <TextButton text="Cancel" type="white" onClick={()=>closePopup()} />
                <Button text="Add" type="primary" isDisabled={isButtonDisabled}
                    onClick={handleCreateClicked} />

            </FlexLayout>

        </PopupContainer>
    );
}



