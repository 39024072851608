import { ILanguage } from "./ILanguage";

export const languagePT: ILanguage = {
    DESIGNSYSTEM: {
        Clear: "Limpar",
        ClearSelection: "Limpar Seleção",
        All: "Todos",
        TypeHere: "Digite aqui",

        TABLE: {
            NoResults: "Nenhum resultado encontrado",
            NoResultsText: "Não encontramos o que procura, por favor procure novamente",
            RowsPerPage: "Items por pág.",
            Of: "de",
        },

        CALENDAR: {
            Monday: "Se",
            Tuesday: "Te",
            Wednesday: "Qa",
            Thursday: "Qi",
            Friday: "Sx",
            Saturday: "Sa",
            Sunday: "Do",
        }
    }
}