import { ILanguage } from "./ILanguage";

export const languageEN: ILanguage = {
    DESIGNSYSTEM: {
        Clear: "Clear",
        ClearSelection: "Clear Selection",
        All: "All",
        TypeHere: "Type Here",

        TABLE: {
            NoResults: "No results found",
            NoResultsText: "We couldn't find what you're looking for, please search again",
            RowsPerPage: "Rows per page",
            Of: "of",
        },

        CALENDAR: {
            Monday: "Mo",
            Tuesday: "Tu",
            Wednesday: "We",
            Thursday: "Th",
            Friday: "Fr",
            Saturday: "Sa",
            Sunday: "Su",
        }
    }
}