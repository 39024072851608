import "./Empty.css";

import { ReactComponent as SearchSVG } from "../../../assets/icons/search-icon.svg";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

const renderCircles = () => (
    <div id="empty-circles-container">
        <div className="empty-first-circle">
            <div className="empty-second-circle">
                <div className="empty-third-circle">
                    <div className="empty-circle">
                        <div className="empty-circle">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export interface IEmptyProps {
    title?: string;
    subtitle?: string;
}


export function Empty(props: IEmptyProps) {
    return (<div className="empty-container">
        <div className="empty-icon">{renderCircles()}<SearchSVG /></div>
        <div className="empty-first-label subtitle">{props.title ? props.title : translate("DESIGNSYSTEM.TABLE.NoResults")}</div>
        <div className="empty-second-label">{props.subtitle ? props.subtitle : translate("DESIGNSYSTEM.TABLE.NoResultsText")}</div>
    </div>
    )
}