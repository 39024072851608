import { ILanguage } from "./ILanguage";
import { ObjectHelper } from "../../helpers/ObjectHelper";
import { StringHelper } from "../../helpers/StringHelper";
import { languageEN } from "./LanguageEN";
import { languagePT } from "./LanguagePT";

export type LanguageLocale = "en-EN" | "pt-PT";

export class InternationalizationProvider {
    static currentLocale: LanguageLocale = "en-EN";

    static languages: { [key: string]: ILanguage } = {
        "en-EN": languageEN,
        "pt-PT": languagePT,
    };

    public static init(): Promise<void> {
        let locale = localStorage.getItem("VLMT-BONUS-LOCALE") as LanguageLocale;
        this.setCurrentLocale(locale || "en-EN", false);
        return Promise.resolve();
    }

    static getCurrentLocale() {
        return InternationalizationProvider.currentLocale;
    }

    static setCurrentLocale(locale: LanguageLocale, refresh = true) {
        localStorage.setItem("VLMT-BONUS-LOCALE", locale);
        InternationalizationProvider.currentLocale = locale;
        if (refresh) window.location.reload();
    }

    static getCalendarWeekDays(): string[] {
        return ObjectHelper.resolve("CALENDAR.Weekdays", this.languages[this.currentLocale]);
    }

    static getCalendarMonths(): string[] {
        return ObjectHelper.resolve("CALENDAR.Months", this.languages[this.currentLocale]);
    }

    static translate(locale: LanguageLocale, key: string, ...format: string[]) {
        const label = ObjectHelper.resolve(key, this.languages[locale]);

        return StringHelper.formatString(label as string, ...format);
    }
}
