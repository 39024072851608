import "./ListMainCommandsPage.css";

import { ColumnDefinition, IOrderInfo } from "../../../../lib/components/table/TableInterfaces";
import { EditSVG, PlusSVG, RemoveSVG } from "../../../../lib/assets/icons";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AddCommandPopup } from "../../components/CommandManagerPopup/AddCommandPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { CommandService } from "../../services/CommandService";
import { CommandSummaryDTO } from "../../models/serviceDtos/CommandSummaryDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { Notification } from './../../components/notifications/Notification';
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";

interface IListsProps {

}

const commandService = new CommandService();

export function ListMainCommandsPage(props: IListsProps) {
	const navigate = useNavigate();
	const openPopup = usePopup();
	const createNotification = useCreateNotification();

	const [commands, setCommands] = useState([] as CommandSummaryDTO[]);
	const [currentPage, setCurrentPage] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalCommands, setTotalCommands] = useState(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([{ columnKey: "name", direction: "ASC" },]);




	/****************************
	 * DATA REQUESTS
	 *****************************/

	const loadCommandsData = useCallback(() => {

		setIsLoading(true);

		commandService.getMainCommands({ pageLength: itemsPerPage, page: currentPage }).then((commandsRes) => {
			setCommands(commandsRes.items);
			setTotalCommands(commandsRes.totalItems);
			setIsLoading(false);
		}).catch((error) => {
			if (error.code === "ERR_NETWORK") {
				//navigate("/nice_try");
			}
		});
	}, [itemsPerPage, currentPage, setIsLoading, setTotalCommands, setCommands])

	/****************************
	 * DATA MANIPULATION EFFECTS
	 *****************************/
	useEffect(() => {
		loadCommandsData();
	}, [currentPage, itemsPerPage]);


	/****************************
	 * USER ACTIONS
	 *****************************/
	const handleAddCommandClicked = useCallback(() => {
		openPopup(<AddCommandPopup onCompleted={loadCommandsData} />)
	}, [openPopup]);

	const handleOnPageAndItemsChanged = useCallback(
		(items: number, currentPage: number) => {
			setItemsPerPage(items);
			setCurrentPage(currentPage);
		},
		[setItemsPerPage, setCurrentPage]
	);

	const updateColumnOrderInfo = useCallback(
		(orderedPriorities: IOrderInfo[]) => {
			setOrderInfoColumns(orderInfoColumns);
			console.log("New order priorities array", orderedPriorities);
		},
		[orderInfoColumns]
	);

	const handleDeleteOnConfirmClicked = useCallback((commandId: number) => {
		if (!commandId) return;

		commandService.deleteCommand(commandId)
			.then(() => {
				createNotification(
					<Notification
						type="success"
						title={"Success!"}
						text={'Success deleting command!'}
					></Notification>
				);
				loadCommandsData();
			})
			.catch((error) => {
				if (error.response.data) {
					openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
				} else {
					openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
				}
			});
	}, [loadCommandsData, createNotification, openPopup]);

	const handleDeleteCommandClicked = useCallback((commandId: number) => {
		openPopup(
			<WarningPopup onConfirmClicked={() => handleDeleteOnConfirmClicked(commandId)}>
				Are you sure you want to delete this command?
			</WarningPopup>
		)
	}, [openPopup, handleDeleteOnConfirmClicked]);

	/****************************
	 * CSS & HTML
	 *****************************/

	const columns: ColumnDefinition<CommandSummaryDTO>[] = [
		{
			columnKey: "commandId",
			cellRenderProp: (v: CommandSummaryDTO) => v.commandId,
			headerRender: "Command ID",
			isMobilePrimaryCell: true,
			width: "15%",
		},
		{
			columnKey: "name",
			cellRenderProp: (v: CommandSummaryDTO) => v.name,
			headerRender: "Name",
			isMobilePrimaryCell: true,
			isSortable: true,
			width: "25%",
		},
		{
			columnKey: "description",
			cellRenderProp: (v: CommandSummaryDTO) => v.description,
			headerRender: "Description",
		},
		{
			columnKey: "view",
			cellRenderProp: (v: CommandSummaryDTO) => <Link to={'/commands/' + v.commandId} className="link"><IconButton size={40} type="tertiary" icon={<EditSVG />} /></Link>,
			isMobileHeaderIcon: true,
			width: "24px"
		},
		{
			columnKey: "delete",
			cellRenderProp: (v: CommandSummaryDTO) => <IconButton size={40} icon={<RemoveSVG />} type="tertiary" onClick={() => handleDeleteCommandClicked(v.commandId)} />,
			isMobileHeaderIcon: true,
			width: "24px"
		}
	];


	return <PageContainer className="commands-page">
		<div className="title-section">
			<h1>Main Commands</h1>
			<Button
				className="addcommand-button"
				icon={<PlusSVG />}
				text="Add Command"
				type="primary"
				onClick={handleAddCommandClicked}
			/>
		</div>

		<div className="section buttons">
			<div className="item page">
				<div className="flex-row">
					<ResponsiveDataTable
						columnDefinitions={columns}
						items={commands || []}
						totalitems={totalCommands}
						onClickRow={(item) => navigate(`/commands/${item.commandId}`)}
						paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 25, 50, 100] }}
						currentpage={currentPage}
						isLoading={isLoading}
						onPageAndItemsChanged={handleOnPageAndItemsChanged}
						showSelectedRowHighlighted={true}
						orderColumns={orderInfoColumns}
						onOrderChanged={(orderInfoPriority) => updateColumnOrderInfo(orderInfoPriority)}
					/>
				</div>
			</div>
		</div>
	</PageContainer>;
}
