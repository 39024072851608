import * as Luxon from "luxon";

export class DateTimeHelper {
    static formatDateTime(date?: string | null) {
        return date && Luxon.DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm")
    }

    static formatDate(date?: string | null) {
        return date && Luxon.DateTime.fromISO(date).toFormat("dd/MM/yyyy")
    }

    static outputJSAsIso(date?: Date) {
        return date && Luxon.DateTime.fromJSDate(date).toISO();
    }

    static outputAsIso(date?: Luxon.DateTime) {
        return date && date.setZone(Luxon.DateTime.local().zone).toISO();
    }
}
