import { CommandCreateDTO } from "../models/serviceDtos/CreateCommandDTO";
import { CommandDetailsDTO } from "../models/serviceDtos/CommandDetailsDTO";
import { CommandSummaryDTOListWithPagination } from "../models/serviceDtos/CommandSummaryDTOListWithPagination";
import { CommandUpdateDTO } from "../models/serviceDtos/UpdateCommandDTO";
import { CommandsRequestWithPagination } from "../models/CommandsRequestWithPagination";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import axios from "axios";

const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`

export class CommandService {
  async getMainCommands(
    commandsRequestWithPagination: CommandsRequestWithPagination
  ): Promise<CommandSummaryDTOListWithPagination> {
    return await axios
      .request<CommandSummaryDTOListWithPagination>({
        method: "GET",
        url: Route("api/Commands"),
        params: commandsRequestWithPagination,
      })
      .then((res) => res.data);
  }

	async getCommandById(commandId: number) : Promise<CommandDetailsDTO>{
		return await axios
			.request({
				method: "GET",
				url: Route(`api/Commands/${commandId}`),
			})
			.then((res) => res.data)
			.catch((error) => {
				throw new Error(error.response.data.message);
			});
	}

  async createCommand(request: CommandCreateDTO) : Promise<any>{
		return await axios
			.request({
				method: "POST",
				url: Route(`api/Commands/`),
        		data: request
			})
			.then((res) => res.data)
			.catch((error) => {
				throw new Error(error.response.data.message);
			});
	}

  async updateCommand(commandId:number,request: CommandUpdateDTO) : Promise<any>{
		return await axios
			.request({
				method: "PUT",
				url: Route(`api/Commands/${commandId}`),
        data: request
			})
			.then((res) => res.data)
			.catch((error) => {
				throw new Error(error.response.data.message);
			});
	}

  async deleteParameter(commandId:number,parameterId: number) : Promise<any>{
		return await axios
			.request({
				method: "DELETE",
				url: Route(`api/Commands/${commandId}/parameter/${parameterId}`),
			})
			.then((res) => res.data)
			.catch((error) => {
				throw new Error(error.response.data.message);
			});
	}

	async deleteCommand(commandId:number) : Promise<any>{
		return await axios
			.request({
				method: "DELETE",
				url: Route(`api/Commands/${commandId}`),
			})
			.then((res) => res.data)
			.catch((error) => {
				throw new Error(error.response.data.message);
			});
	}
}
