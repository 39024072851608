import "./ViewCommandPage.css";

import { CommandDetailsDTO, ParameterDetailsDTO } from "../../models/serviceDtos/CommandDetailsDTO";
import { CommandHistoryDTO, CommandHistoryEntity, CommandHistoryOperation, ParameterHistoryOperation } from "../../models/serviceDtos/CommandHistoryDTO";
import { CommandHistoryDTOListWithPagination, CommandHistoryService } from "../../services/CommandHistoryService";
import { EditSVG, FolderSVG, PlusSVG, RemoveSVG } from "../../../../lib/assets/icons";
import { IRightSideBarButtons, RightSideBar } from "../../../../common/components/right-side-bar/RightSideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { requiredValidator, useFormControl } from "../../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AddCommandPopup } from "../../components/CommandManagerPopup/AddCommandPopup";
import { AddParameterPopup } from "../../components/AddParameterPopup/AddParameterPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { CommandService } from "../../services/CommandService";
import { CommandSummaryDTO } from "../../models/serviceDtos/CommandSummaryDTO";
import { CommandUpdateDTO } from "../../models/serviceDtos/UpdateCommandDTO";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { EditParameterPopup } from "../../components/EditParameterPopup/EditParameterPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from '../../../../lib/layouts/containers/flex/FlexLayout';
import { FormFieldCodeArea } from "../../../../lib/components/form/form-field/FormFieldCodeArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { Notification } from './../../components/notifications/Notification';
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { useServiceCallPro } from "../../utils/UseServiceCall";

/**Mappers */

const parameterDataTypeToString = (datatype: number) => {
    switch (datatype) {
        case 0: return 'INT'
        case 1: return 'STRING'
        case 2: return 'DOUBLE'
        case 3: return 'FLOAT'
        case 4: return 'CHAR'
        case 5: return 'BOOL'
        default: return 'Unknown'
    }
}




const commandService = new CommandService();
const commandHistoryService = new CommandHistoryService();

interface ICommandHistoryItemProps {
    item: CommandHistoryDTO
}

const mapTargetEntity = ( targetEntityType : number ) => {
    switch (targetEntityType){
        case CommandHistoryEntity.COMMAND : return "Command";
        case CommandHistoryEntity.PARAMETER : return "Parameter";
    }
}


const mapOperationCommand = ( operation: number ) => {
    switch (operation){
        case CommandHistoryOperation.CREATE : return "Create";
        case CommandHistoryOperation.UPDATE_BEHAVIOR : return "Behavior";
        case CommandHistoryOperation.UPDATE_DESCRIPTION : return "Description";
        case CommandHistoryOperation.UPDATE_NAME : return "Name";
    }
}

const mapOperationParameter = ( operation: number ) => {
    switch (operation){
        case ParameterHistoryOperation.CREATE : return "Create";
        case ParameterHistoryOperation.DELETE : return "Delete";
        case ParameterHistoryOperation.UPDATE_DATATYPE : return "DataType";
        case ParameterHistoryOperation.UPDATE_NAME : return "Name";
    }
}

const mapOperation = ( targetEntityType : number, operation: number ) => {
    switch (targetEntityType){
        case CommandHistoryEntity.COMMAND : return mapOperationCommand(operation) ;
        case CommandHistoryEntity.PARAMETER : return mapOperationParameter(operation);
    }
}

export function CommandHistoryItem(props: ICommandHistoryItemProps) {


    


    return (<>
        <FlexLayout className="command-history-item" direction="vertical">
            <div className="command-history-item-date">{DateTimeHelper.formatDateTime(props.item.createdAt)}</div>
            <div className="command-history-item-entity">{mapTargetEntity(props.item.targetEntity)}</div>
            <div className="command-history-item-op">{mapOperation(props.item.targetEntity, props.item.operation)}</div>
            {props.item.oldValue && <div className="command-history-item-old">Old: {props.item.oldValue}</div>}
            {props.item.newValue && <div className="command-history-item-new">New: {props.item.newValue}</div>}
        </FlexLayout>
        <LineSeparator />
    </>
    );
}
