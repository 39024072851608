import "./EditParameterPopup.css";

import { CommandDetailsDTO, ParameterDetailsDTO } from "../../models/serviceDtos/CommandDetailsDTO";
import { DataTypeOption, dataTypeOptions } from "../AddParameterPopup/AddParameterPopup";
import { requiredValidator, useFormControl } from "../../../../lib/components/form/Form";
import { useCallback, useMemo } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { CommandCreateDTO } from "../../models/serviceDtos/CreateCommandDTO";
import { CommandService } from "../../services/CommandService";
import { CommandUpdateDTO } from "../../models/serviceDtos/UpdateCommandDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { useServiceCallPro } from "../../utils/UseServiceCall";

interface IProps {
    commandDetails: CommandDetailsDTO
    parameterDetail: ParameterDetailsDTO
    onCompleted: () => void;
}

const svc = new CommandService();




export function EditParameterPopup(props: IProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const nameFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true, initialValue: props.parameterDetail.name });
    const dataTypeFormControl = useFormControl<DataTypeOption>({ validators: [requiredValidator()], enableAutoValidate: true , initialValue: dataTypeOptions.find(i=>i.key === props.parameterDetail.dataType)});


    /****************************
     * DATA REQUESTS
     *****************************/

    const updateCommandCall = useServiceCallPro(svc.updateCommand);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    const isButtonDisabled = useMemo(() => {
        return updateCommandCall.isLoading;
    }, [updateCommandCall.isLoading]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleEditClicked = useCallback(() => {

        if (!nameFormControl.validate() || !dataTypeFormControl.validate())
            return;

        var requestDTO: CommandUpdateDTO = {
            parametersUpdate: [{
                dataType: dataTypeFormControl.value?.key || -1,
                name: nameFormControl.value || "",
                parameterId: props.parameterDetail.parameterId
            }]
        };

        updateCommandCall.invoke(props.commandDetails.commandId, requestDTO).then((newCommand) => {
            // openPopup(<SuccessPopup>You have created a Space successfully with ID: {newCommand.commandId}</SuccessPopup>);
            props.onCompleted();
            closePopup();
        }).catch((err) => {
            openPopup(<ErrorPopup>{err.code}</ErrorPopup>);
        });
    },[updateCommandCall, nameFormControl, dataTypeFormControl, props.parameterDetail, props.commandDetails, props.onCompleted, openPopup]);

    /****************************
    * CSS & HTML
    *****************************/

    if (updateCommandCall.isLoading) {
        return (
            <PopupContainer className="add-space-popup">
                <Loader />
            </PopupContainer>
        )
    }

    return (
        <PopupContainer className="add-space-popup">

            <h3>Edit Parameter</h3>
            <Spacer mode="vertical" px="16" />

            <FormContainer className="inputs">
                <FormFieldTextInput label="Name" placeholder="Insert a name" formControl={nameFormControl} />
                <FormFieldSelectSingle 
                label="Data Type"
                placeholder="select data type"
                formControl={dataTypeFormControl}  
                idSelector={(v)=>v.key}
                labelSelector={(v)=>v.label}
                options={dataTypeOptions}/>
            </FormContainer>

            <Spacer mode="vertical" px="16" />
            <LineSeparator />

            <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >

                <TextButton text="Cancel" type="white" onClick={()=>closePopup()} />
                <Button text="Edit" type="primary" isDisabled={isButtonDisabled}
                    onClick={handleEditClicked} />

            </FlexLayout>

        </PopupContainer>
    );
}



