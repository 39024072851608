import './FlexLayout.css';

import { CssClassNameBuilder } from '../../../utils/CssClassnameBuilder';
import { useMemo } from 'react';

export interface IStackLayoutProps {
    direction?: 'horizontal' | 'vertical';
    horizontalAlign?: 'start' | 'center' | 'end';
    verticalAlign?: 'start' | 'center' | 'end';
    className?: string;
    children: React.ReactNode;
}

export function FlexLayout(props: IStackLayoutProps) {

    const stackLayoutCss = useMemo(() => {
        let horizontalAlign = props.horizontalAlign || 'start';
        let verticalAlign = props.verticalAlign || 'start';
        return CssClassNameBuilder.new()
            .add("flex-layout")
            .add(props.direction?? 'horizontal')
            .add("horizontal-"+horizontalAlign)
            .add("vertical-"+verticalAlign)
            .addConditional(props.className, props.className)
            .build();
    }, [props.direction, props.horizontalAlign, props.verticalAlign, props.className]);


    return (
        <div className={stackLayoutCss}>{props.children}</div>
    )

}
