import "./NotificationContainer.css";

import React, { useEffect, useRef, useState } from "react";

import { useNotification } from "../../../../lib/infrastructure/ui/UIServices";

interface INotificationContainerProps {}

export function NotificationContainer(props: INotificationContainerProps) {

  const queue = useRef<React.ReactNode[]>([]);

  const { notificationContent } = useNotification();

  const [notifications, setNotifications] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    queue.current = notifications;
  }, [notifications])

  useEffect(() => {
    let newNotifications: React.ReactNode[] = [...notifications];
    if (notificationContent) {
      newNotifications.push(notificationContent);
      setTimeout(() => {
        setNotifications(queue.current.filter(r => r !== notificationContent))
      }, 3000);
      setNotifications(newNotifications);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationContent]);





  return (
    <div className="notification-container">
      {notifications.map((notification, idx) => {

        return <React.Fragment key={idx}> {notification}</React.Fragment>;
      })}
    </div>
  );
}
