import { CodeArea } from "../code-area/CodeArea";
import CodeEditor from '@uiw/react-textarea-code-editor';
import { CssClassNameBuilder } from "../../../utils/CssClassnameBuilder";
import { FormField } from "./FormField";
import { IFormControl } from "../Form";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";

interface IformFieldCodeAreaProps<T> {
    label?: string;
    formControl: IFormControl<T>;
    icon?: React.ReactNode;
    placeholder?: string;
    className?: string;
    rootClassName?: string;
}

export function FormFieldCodeArea<T>(props: IformFieldCodeAreaProps<T>) {
    const placeholder = useMemo(
        () => props.placeholder || translate("DESIGNSYSTEM.TypeHere"),
        [props.placeholder]
    );
    const rootCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .addConditional(props.rootClassName, props.rootClassName)   
            .build();
    }, [
        props.rootClassName,
    ]);

    return (
        <FormField label={props.label} formControl={props.formControl} className={rootCss} >
            <CodeArea
                value={props.formControl.value}
                placeholder={placeholder}
                icon={props.icon}
                isDisabled={props.formControl.isDisabled}
                onChange={(val) => props.formControl.setValue(val)}
                hasError={props.formControl.hasErrors}
                className={props.className}
            />
        </FormField>
    );
}
