import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";

import { AppPortal } from "./lib/layouts/portal/AppPortal";
import { AppRoutingModule } from "./modules/App.RoutingModule";

function App() {
  return (
    <AppPortal>
      <Routes>
        <Route path="/" element={<Navigate to="/commands" />} />
        <Route path="/notfound" element={<>Not found</>} />
        <Route path="*" element={<AppRoutingModule />} />
      </Routes>
    </AppPortal>
  );
}

export default App;
