import "./Overlay.css";

import { useCallback, useMemo, useRef } from "react";

import { CssClassNameBuilder } from "../../../utils/CssClassnameBuilder";

interface IOverlayProps {
  children: React.ReactNode;
  className?: string;
  onMouseDown?: (ev: React.MouseEvent<HTMLDivElement>) => void;
}

export function Overlay(props: IOverlayProps) {

    const overlayCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("overlay")
            .addConditional(props.className, props.className)
            .build()
    }, [props.className]);


  return (
    <div className={overlayCss} onMouseDown={props.onMouseDown}>
        {props.children}
    </div>
  );
}
