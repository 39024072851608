import "./Tooltip.css";

import { useCallback, useRef, useState } from "react";

interface ITooltipProps {
  children: React.ReactNode;
  text: string;
}

export function Tooltip(props: ITooltipProps) {
  const ref = useRef<HTMLInputElement>(null);
  const hostRef = useRef<HTMLInputElement>(null);

  const [tooltipPosStyle, setTooltipPosStyle] = useState<{}>({});

  const handleOnMouseEnter = useCallback(() => {
    if (hostRef.current && ref.current) {
      const hostPosTop = hostRef.current.getBoundingClientRect().top;
      const hostPosLeft = hostRef.current.getBoundingClientRect().left;
      const hostRefWidth = hostRef.current.clientWidth;
      const posLeft = ref.current.getBoundingClientRect().left;
      const refWidth = ref.current.clientWidth;
      const remainingHeight = window.innerHeight - hostPosTop;
      const remainingWidth = (window.innerWidth - hostPosLeft) - hostRefWidth;
      if (hostPosTop > remainingHeight) {
        if (refWidth / 2 > remainingWidth) {
          setTooltipPosStyle({
            bottom: "calc(100% + 4px)",
            right: 0,
          });
        } else if (refWidth / 2 > posLeft) {
          setTooltipPosStyle({
            bottom: "calc(100% + 4px)",
            left: 0,
          });
        } else {
          setTooltipPosStyle({
            bottom: "100%",
            left: "50%",
            transform: "translate(-50%, -4px)",
          });
        }
      } else {
        if (refWidth / 2 > remainingWidth) {
          setTooltipPosStyle({
            top: "calc(100% + 4px)",
            right: 0,
          });
        } else if (refWidth / 2 > posLeft) {
          setTooltipPosStyle({
            top: "calc(100% + 4px)",
            left: 0,
          });
        } else {
          setTooltipPosStyle({
            top: "100%",
            left: "50%",
            transform: "translate(-50%, 4px)",
          });
        }
      }
    }
  }, []);

  return (
    <div
      ref={hostRef}
      className="tooltip-host"
      onMouseEnter={() => handleOnMouseEnter()}
    >
      {props.children}
      <div ref={ref} className="caption tooltip" style={tooltipPosStyle}>
        {props.text}
      </div>
    </div>
  );
}
