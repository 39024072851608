import "./FormContainer.css";

import { CssClassNameBuilder } from "../../../utils/CssClassnameBuilder";
import { useMemo } from "react";

export interface IFormContainerProps {
    children: React.ReactNode
    mode?: "horizontal" | "vertical"
    className?: string;
}

export function FormContainer(props: IFormContainerProps) {

    const formContainerCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("form-container")
            .addConditional(props.className, props.className)
            .build()
    }, [props.className]);

    return <div className={formContainerCss}>
        {props.children}
    </div>
}
