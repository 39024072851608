import { UISContextProvider } from "../ui/UIServices";

interface ISPAAppProps {
  children: React.ReactNode;
}

export function SPAApp(props: ISPAAppProps) {
  return (
    <UISContextProvider>
      {props.children}
    </UISContextProvider>
  );
}
