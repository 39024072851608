export class ObjectHelper {
    static resolve(path: string, obj: any) {
        return path.split(".").reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
        }, obj);
    }

    static deepEqual(object1: any, object2: any) {

        if (object1 === object2) return true;

        if (!ObjectHelper.isObject(object1) || !ObjectHelper.isObject(object2)) {
            return false;
        }

        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = ObjectHelper.isObject(val1) && ObjectHelper.isObject(val2);
            if (
                areObjects && !ObjectHelper.deepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }

        return true;
    }

    static isObject(object: any) {
        return object != null && typeof object === 'object';
    }
}
