import { CommandHistoryDTO } from "../models/serviceDtos/CommandHistoryDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import axios from "axios";

const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`

export interface CommandHistoryRequestWithPagination {
    pageLength: number;
    page: number;
}

export interface CommandHistoryDTOListWithPagination {
    items: CommandHistoryDTO[];
    totalItems: number;
}


export class CommandHistoryService {
	async getCommandHistory(commandId : string, request: CommandHistoryRequestWithPagination
	  ): Promise<CommandHistoryDTOListWithPagination> {
		return await axios
		  .request<CommandHistoryDTOListWithPagination>({
			method: "GET",
			url: Route(`api/commands/${commandId}/commandsHistory`),
			params: request,
		  })
		  .then((res) => res.data);
	  }
}
