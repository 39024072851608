/* eslint-disable no-unreachable */

import "./MiniDataTable.css";

import {
  ColumnDefinition,
  IOrderInfo,
  PaginationOptions,
} from "./TableInterfaces";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ArrayHelper } from "../../utils/ArrayHelper";
import { CssClassNameBuilder } from "../../utils/CssClassnameBuilder";
import { Empty } from "./empty/Empty";
import { IMobileTableCellProps as IMobileTableCell } from "./table-cell/MobileTableCell";
import { ITableRowProps as ITableRow } from "./table-row/TableRow";
import { Loader } from "../loader/Loader";
import { MobileTableRow } from "./table-row/MobileTableRow";
import { TableFooterMobile } from "./table-footer/TableFooterMobile";

interface IMiniDataTableProps<T> {
  className?: string;
  items: T[];
  columnDefinitions: ColumnDefinition<T>[];
  totalitems: number;
  currentpage?: number;
  paginationOptions?: PaginationOptions;
  isLoading?: boolean;
  maxBodyHeight?: number;
  orderColumns?: IOrderInfo[];
  onOrderChanged?: (orderInfos: IOrderInfo[]) => void;
  //onItemsPerPageChanged?: (rowsPerPage: number, newPage: number) => void;
  //onCurrentPageChanged?: (rowsPerPage: number, newPage: number) => void;
  onClickRow?: (item: T) => void;
  showSelectedRowHighlighted?: boolean;
  onPageAndItemsChanged?: (rowsPerPage: number, newPage: number) => void;
}

export function MiniDataTable<T>(props: IMiniDataTableProps<T>) {
  const [orderInfos, setOrderInfos] = useState<IOrderInfo[]>([]);
  const [clickedRowIdx, setClickedRowIdx] = useState<number | null>(null);

  useEffect(() => {
    setOrderInfos(props.orderColumns || []);
  }, [props.orderColumns]);

  /****************************
   * DATA MANIPULATION EFFECTS *
   *****************************/

  const hasItems = useMemo(() => props.items.length > 0, [props.items]);

  const isFooterDisabled = useMemo(
    () => ((props.isLoading && props.currentpage === 0) || !props.paginationOptions) || !hasItems,
    [props.isLoading, props.currentpage, hasItems, props.paginationOptions]
  );

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSortColumnClick = useCallback(
    (columnKey: string | undefined) => {
      if (!columnKey) return;

      let newColumnOrder: IOrderInfo = {
        columnKey: columnKey,
        direction: "NONE",
      };

      const oldColumnOrderIdx = orderInfos.findIndex(
        (ord) => ord.columnKey === columnKey
      );

      var oldColumnOrder =
        oldColumnOrderIdx > -1 ? orderInfos.at(oldColumnOrderIdx) : undefined;

      switch (oldColumnOrder?.direction) {
        case "ASC":
          newColumnOrder.direction = "DESC";
          break;
        case "DESC":
          newColumnOrder.direction = "NONE";
          break;
        default:
          newColumnOrder.direction = "ASC";
          break;
      }

      let newOrderState: IOrderInfo[] = orderInfos.filter(
        (ord) => ord.columnKey !== columnKey
      );

      switch (newColumnOrder.direction) {
        case "ASC":
          newOrderState.push(newColumnOrder);
          break;
        case "DESC":
          ArrayHelper.insertIntoPosition(
            newOrderState,
            oldColumnOrderIdx,
            newColumnOrder
          );
          break;
        default:
          break;
      }

      setOrderInfos(newOrderState);
      props.onOrderChanged && props.onOrderChanged(newOrderState);
    },
    [orderInfos, setOrderInfos, props.onOrderChanged]
  );

  const handleRowClicked = useCallback(
    (item: T) => {
      props.onClickRow && props.onClickRow(item);
    },
    [props.onClickRow]
  );

  /****************************
   * CSS && HTML
   *****************************/

  const tableCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("mobile-table-container")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const rows = useMemo(() => {
    let mappedRows = props.items.map(
      (item): ITableRow => ({
        cells: props.columnDefinitions.map(
          (column): IMobileTableCell => ({
            children: column.cellRenderProp && column.cellRenderProp(item),
            label: column.headerRender && column.headerRender,
            isMobilePrimary: column.isMobilePrimaryCell,
            isMobileIcon: column.isMobileHeaderIcon,
            className: column.className,
            isStatusCell: column.isStatusCell,
            isVisible: column.isVisible
          })
        ),
      })
    );

    return mappedRows.map((row, idx) => (
      <MobileTableRow
        key={idx}
        className={row.className}
        cells={row.cells}
        onClick={(r) => {
          handleRowClicked(props.items[idx]);
          if (idx === clickedRowIdx) { setClickedRowIdx(null) }
          else
            setClickedRowIdx(idx)
        }}
        showSelectedRowHighlighted={props.showSelectedRowHighlighted && clickedRowIdx === idx ? true : false}
      />
    ));
  }, [props.items, props.columnDefinitions, handleRowClicked, clickedRowIdx, setClickedRowIdx, props.showSelectedRowHighlighted]);

  return (
    <div className={tableCss}>
      {props.isLoading ? (
        <div className="mobile-table-empty-loader-container">
          <Loader></Loader>
        </div>
      ) : hasItems ? (
        <div className="mobile-table-rows-container">{rows}</div>
      ) : (
        <div className="mobile-table-empty-loader-container">
          <Empty />
        </div>
      )}
      {isFooterDisabled ? null : (
        <TableFooterMobile
          currentPage={props.currentpage}
          paginationOptions={props.paginationOptions || undefined}
          totalItems={props.totalitems}
          //onCurrentPageChanged={props.onCurrentPageChanged}
          onPageAndItemsChanged={props.onPageAndItemsChanged}
        />
      )}
    </div>
  );
}
