
export interface CommandHistoryDTO {
  commandHistoryId: number 
  operation: number 
  operationDetails?: string 
  targetEntity: number 
  targetEntityId: number 
  oldValue?: string
  newValue?: string 
  createdAt : string 
  commandId : number 
}

export enum CommandHistoryEntity
{
    COMMAND = 0,
    PARAMETER = 1
}


export enum CommandHistoryOperation
{
    CREATE = 0,
    UPDATE_NAME = 1,
    UPDATE_DESCRIPTION = 2,
    UPDATE_BEHAVIOR = 3
}



export enum ParameterHistoryOperation
{
    CREATE = 0,
    UPDATE_NAME = 1,
    UPDATE_DATATYPE = 2,
    DELETE = 100
}



export enum ParameterDataType
{
    INT = 0,
    STRING = 1,
    DOUBLE = 2,
    FLOAT = 3,
    CHAR = 4,
    BOOL = 5
}

