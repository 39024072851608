import "./ViewCommandPage.css";

import { CommandDetailsDTO, ParameterDetailsDTO } from "../../models/serviceDtos/CommandDetailsDTO";
import { CommandHistoryDTOListWithPagination, CommandHistoryService } from "../../services/CommandHistoryService";
import { DocumentSquareSVG, EditSVG, FolderSVG, PlusSVG, RemoveSVG } from "../../../../lib/assets/icons";
import { IRightSideBarButtons, RightSideBar } from "../../../../common/components/right-side-bar/RightSideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { requiredValidator, useFormControl } from "../../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AddCommandPopup } from "../../components/CommandManagerPopup/AddCommandPopup";
import { AddParameterPopup } from "../../components/AddParameterPopup/AddParameterPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { CommandHistoryDTO } from "../../models/serviceDtos/CommandHistoryDTO";
import { CommandHistoryItem } from "./CommandHistoryItem";
import { CommandService } from "../../services/CommandService";
import { CommandSummaryDTO } from "../../models/serviceDtos/CommandSummaryDTO";
import { CommandUpdateDTO } from "../../models/serviceDtos/UpdateCommandDTO";
import { EditParameterPopup } from "../../components/EditParameterPopup/EditParameterPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from '../../../../lib/layouts/containers/flex/FlexLayout';
import { FormFieldCodeArea } from "../../../../lib/components/form/form-field/FormFieldCodeArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { Notification } from './../../components/notifications/Notification';
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { useServiceCallPro } from "../../utils/UseServiceCall";

/**Mappers */

const parameterDataTypeToString = (datatype: number) => {
  switch (datatype) {
    case 0: return 'INT'
    case 1: return 'STRING'
    case 2: return 'DOUBLE'
    case 3: return 'FLOAT'
    case 4: return 'CHAR'
    case 5: return 'BOOL'
    default: return 'Unknown'
  }
}




const commandService = new CommandService();
const commandHistoryService = new CommandHistoryService();

export function ViewCommandPage() {

  const { commandId } = useParams();
  const navigate = useNavigate();
  const openPopup = usePopup();
  const createNotification = useCreateNotification();

  const [command, setCommand] = useState<CommandDetailsDTO>();
  const [history, setHistory] = useState<CommandHistoryDTO[]>();
  const [showHistory, setShowHistory] = useState(false);

  const updateCommandCall = useServiceCallPro(commandService.updateCommand);
  const getCommandByIdCall = useServiceCallPro(commandService.getCommandById);
  const deleteParameterCall = useServiceCallPro(commandService.deleteParameter);

  const nameFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true, initialValue: "" });
  const descriptionFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true, initialValue: "" });
  const behaviorFormControl = useFormControl<string>({ validators: [], enableAutoValidate: true, initialValue: "" });

  /****************************
  * DATA REQUESTS
  *****************************/
  const getCommandById = useCallback(() => {
    if (!commandId) return;

    getCommandByIdCall.invoke(parseInt(commandId))
      .then((res) => {
        setCommand(res)
        nameFormControl.setValue(res.name);
        descriptionFormControl.setValue(res.description);
        behaviorFormControl.setValue(res.behavior);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          navigate("/nice_try");
        } else {
          navigate("/not-found");
        }
      });
  }, [commandId, getCommandByIdCall, nameFormControl, setCommand, navigate])

  const getCommandHistoryById = useCallback(() => {
    if (!commandId) return;


    commandHistoryService.getCommandHistory(commandId, { pageLength: 200, page: 0 }).then((commandsRes) => {
      setHistory(commandsRes.items);
    }).catch((error) => {
      if (error.code === "ERR_NETWORK") {
        //navigate("/nice_try");
      }
    });
  }, [commandId])

  const deleteParameter = useCallback((parameterId: number) => {
    if (!commandId || !parameterId) return;


    deleteParameterCall.invoke(parseInt(commandId), parameterId)
      .then(() => {
        getCommandById();
        createNotification(
          <Notification
            type="success"
            title={"Success!"}
            text={'Success deleting parameter!'}
          ></Notification>
        );
      })
      .catch((error) => {
        if (error.response.data) {
          openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
        } else {
          openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        }
      });
  }, [commandId, deleteParameterCall, openPopup, createNotification, getCommandById])

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/
  useEffect(() => {
    getCommandById();
    getCommandHistoryById();
  }, [commandId]);

  const isLoading = useMemo(() => {
    return updateCommandCall.isLoading || updateCommandCall.isLoading || deleteParameterCall.isLoading;
  }, [getCommandByIdCall.isLoading, updateCommandCall.isLoading, deleteParameterCall.isLoading]);


  useEffect(() => {
    nameFormControl.setIsDisabled(getCommandByIdCall.isLoading || updateCommandCall.isLoading || deleteParameterCall.isLoading)
    descriptionFormControl.setIsDisabled(getCommandByIdCall.isLoading || updateCommandCall.isLoading || deleteParameterCall.isLoading)
    behaviorFormControl.setIsDisabled(getCommandByIdCall.isLoading || updateCommandCall.isLoading || deleteParameterCall.isLoading)
  }, [getCommandByIdCall.isLoading, updateCommandCall.isLoading, deleteParameterCall.isLoading]);



  const isButtonDisabled = useMemo(() => {
    return updateCommandCall.isLoading;
  }, [updateCommandCall.isLoading]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSaveCommandClicked = useCallback(() => {
    if (!commandId || !nameFormControl.validate() || !descriptionFormControl.validate())
      return;

    var requestDTO: CommandUpdateDTO = {
      name: nameFormControl.value,
      description: descriptionFormControl.value,
      behavior: behaviorFormControl.value,
    };

    updateCommandCall.invoke(parseInt(commandId), requestDTO).then((newCommand) => {
      getCommandHistoryById();
      createNotification(
        <Notification
          type="success"
          title={"Success!"}
          text={'Success updating command!'}
        ></Notification>
      );
    }).catch((err) => {
      openPopup(<ErrorPopup>{err.code}</ErrorPopup>);
    });
  }, [updateCommandCall, nameFormControl, descriptionFormControl, behaviorFormControl, commandId, createNotification, openPopup]);


  const handleAddCParameterClicked = useCallback(() => {
    command && openPopup(<AddParameterPopup onCompleted={getCommandById} commandDetails={command} />)
  }, [openPopup, command, getCommandById]);

  const handleEditParameterClicked = useCallback((v: ParameterDetailsDTO) => {
    command && openPopup(<EditParameterPopup onCompleted={getCommandById} commandDetails={command} parameterDetail={v} />)
  }, [openPopup, command, getCommandById]);

  const handleDeleteParameterClicked = useCallback((v: ParameterDetailsDTO) => {
    deleteParameter(v.parameterId)
  }, [deleteParameter]);

  const handleAddSubCommandClicked = useCallback(() => {
    openPopup(<AddCommandPopup parentCommandId={commandId ? parseInt(commandId) : undefined} onCompleted={getCommandById} />)
  }, [commandId, openPopup, getCommandById]);

  const handleDeleteOnConfirmClicked = useCallback((commandId: number) => {
    if (!commandId) return;

    commandService.deleteCommand(commandId)
      .then(() => {
        createNotification(
          <Notification
            type="success"
            title={"Success!"}
            text={'Success deleting command!'}
          ></Notification>
        );
        getCommandById();
      })
      .catch((error) => {
        if (error.response.data) {
          openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
        } else {
          openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        }
      });
  }, [getCommandById, createNotification, openPopup]);

  const handleDeleteSubCommandClicked = useCallback((commandId: number) => {
    openPopup(
      <WarningPopup onConfirmClicked={() => handleDeleteOnConfirmClicked(commandId)}>
        Are you sure you want to delete this command?
      </WarningPopup>
    )
  }, [openPopup, handleDeleteOnConfirmClicked]);


  /****************************
   * CSS & HTML
   *****************************/

  const columnsParameters: ColumnDefinition<ParameterDetailsDTO>[] = useMemo(() => {
    const t: ColumnDefinition<ParameterDetailsDTO>[] = [
      {
        columnKey: "parameterId",
        cellRenderProp: (v: ParameterDetailsDTO) => v.parameterId,
        headerRender: "Parameter ID",
        isMobilePrimaryCell: true,
        width: "15%",
      },
      {
        columnKey: "name",
        cellRenderProp: (v: ParameterDetailsDTO) => v.name,
        headerRender: "Name",
        isMobilePrimaryCell: true,
        isSortable: true,
        width: "25%",
      },
      {
        columnKey: "Type",
        cellRenderProp: (v: ParameterDetailsDTO) => parameterDataTypeToString(v.dataType),
        headerRender: "Description",
        width: "60%",
      },
      {
        columnKey: "view",
        cellRenderProp: (v: ParameterDetailsDTO) => <IconButton icon={<EditSVG />} type="tertiary" size={40} isDisabled={isLoading} onClick={() => handleEditParameterClicked(v)} />,
        isMobileHeaderIcon: true,
        width: "24px"
      },
      {
        columnKey: "delete",
        cellRenderProp: (v: ParameterDetailsDTO) => <IconButton icon={<RemoveSVG />} type="tertiary" size={40} isDisabled={isLoading} onClick={() => handleDeleteParameterClicked(v)} />,
        isMobileHeaderIcon: true,
        width: "24px"
      }
    ]

    return t;
  }, [isLoading]);


  const columns: ColumnDefinition<CommandDetailsDTO>[] = useMemo(() => [
    {
      columnKey: "commandId",
      cellRenderProp: (v: CommandDetailsDTO) => v.commandId,
      headerRender: "Command ID",
      isMobilePrimaryCell: true,
      width: "15%",
    },
    {
      columnKey: "name",
      cellRenderProp: (v: CommandDetailsDTO) => v.name,
      headerRender: "Name",
      isMobilePrimaryCell: true,
      isSortable: true,
      width: "25%",
    },
    {
      columnKey: "description",
      cellRenderProp: (v: CommandDetailsDTO) => <Tooltip text={`Email: ${v.description}`}>{v.description}</Tooltip>,
      headerRender: "Description",
    },
    {
      columnKey: "view",
      cellRenderProp: (v: CommandSummaryDTO) => <Link to={'/commands/' + v.commandId} className="link"><IconButton size={40} type="tertiary" isDisabled={isLoading} icon={<EditSVG />} /></Link>,
      isMobileHeaderIcon: true,
      width: "24px"
    },
    {
      columnKey: "delete",
      cellRenderProp: (v: CommandSummaryDTO) => <IconButton size={40} icon={<RemoveSVG />} type="tertiary" isDisabled={isLoading} onClick={() => handleDeleteSubCommandClicked(v.commandId)} />,
      isMobileHeaderIcon: true,
      width: "24px"
    }
  ], [isLoading]);

  const rightSideBarTopButtons: IRightSideBarButtons[] = useMemo(
    () => [
      {
        icon: <DocumentSquareSVG />,
        onClick: () => { setShowHistory(!showHistory) },
        isActive: showHistory,
      },
    ],
    [showHistory]
  );


  return (
    <FlexLayout className="view-command-page">
      <FlexLayout className="panel-1">
        <PageContainer className="view-command-page-content">

          <div className="title-section">
            <h1>Command Details</h1>
          </div>

          <FlexLayout className="command-details" >

            <FlexLayout className="left-section" direction="vertical" >
              <div className="full-width full-height" >
                <FlexLayout horizontalAlign="end" >
                  <Button
                    className=""
                    icon={<PlusSVG />}
                    text="Save Command"
                    type="primary"
                    isDisabled={isButtonDisabled}
                    onClick={handleSaveCommandClicked}
                  />
                </FlexLayout>
                <FormFieldTextInput className="full-width" label="Name" placeholder="Insert a name" formControl={nameFormControl} />
                <FormFieldTextInput className="full-width" label="Description" placeholder="Insert a description" formControl={descriptionFormControl} />
                <FormFieldCodeArea
                  rootClassName="behavior-container"
                  className="full-width behavior-input"
                  label="Behavior"
                  placeholder="Insert a Behavior"
                  formControl={behaviorFormControl}
                />
              </div>
            </FlexLayout>

            <FlexLayout className="right-section" direction="vertical">
              <div className="full-width" >
                <div className="title-section">
                  <h2>Parameters</h2>
                  <Button
                    className=""
                    icon={<PlusSVG />}
                    text="Add Parameter"
                    type="primary"
                    isDisabled={isButtonDisabled}
                    onClick={handleAddCParameterClicked}
                  />
                </div>
                <ResponsiveDataTable
                  totalitems={command?.parametersDetailsDTOs?.length ?? 0}
                  columnDefinitions={columnsParameters}
                  items={command?.parametersDetailsDTOs || []}
                  showSelectedRowHighlighted={true}
                  isLoading={getCommandByIdCall.isLoading}
                />


                <div className="title-section">
                  <h2>Sub-Commands</h2>
                  <Button
                    className=""
                    icon={<PlusSVG />}
                    text="Add Sub Command"
                    type="primary"
                    isDisabled={isButtonDisabled}
                    onClick={handleAddSubCommandClicked}
                  />
                </div>
                <ResponsiveDataTable
                  totalitems={command?.subCommandsDetailsDTOs?.length ?? 0}
                  columnDefinitions={columns}
                  items={command?.subCommandsDetailsDTOs || []}
                  onClickRow={(item) => navigate(`/commands/${item.commandId}`)}
                  showSelectedRowHighlighted={true}
                  isLoading={getCommandByIdCall.isLoading}
                />


              </div>
            </FlexLayout>
          </FlexLayout>


        </PageContainer>
      </FlexLayout>

      {showHistory && (
        <div className="scroll-parent panel-2 ">
        <PageContainer className="command-history-container">
          <div className="title-section">
            <h1>Command History</h1>
          </div>
          {
            history?.map(h => <CommandHistoryItem item={h} />)
          }
        </PageContainer>
        </div>

      )}

      <div className="panel-3">
        <RightSideBar buttons={rightSideBarTopButtons} />
      </div>
    </FlexLayout>

  );
}
