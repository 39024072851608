import "./PageContainer.css";

import { CssClassNameBuilder } from "../../../utils/CssClassnameBuilder";
import { useMemo } from "react";

interface IPageContainerProps {
    children: React.ReactNode;
    className?: string;
}



export function PageContainer(props: IPageContainerProps) {
    const pageCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("page-container")
            .add("page-scroll")
            .addConditional(props.className, props.className)
            .build()
    }, [props.className]);

    return (
        <div className={pageCss}>
            {props.children}
        </div>



    );
}
