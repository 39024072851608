import { ConfigurationProvider } from '../configuration/ConfigurationProvider';

export class Startup {
    static async Run( app: () => void) {

        await ConfigurationProvider.init();


        app();
    }
}
