import "./CodeArea.css";

import { useCallback, useMemo } from "react";

import CodeEditor from '@uiw/react-textarea-code-editor';
import { CssClassNameBuilder } from "../../../utils/CssClassnameBuilder";

interface ICodeAreaProps<T> {
    value?: any;
    className?: string;
    placeholder?: string;
    isDisabled?: boolean;
    hasError?: boolean;
    onChange?: (value: any) => void;
    onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
    icon?: React.ReactNode;
}

export function CodeArea<T>(props: ICodeAreaProps<T>) {
    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            props.onChange && props.onChange(ev.target.value);
        },
        [props.onChange]
    );

    const textInputCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("code-area")
            .addConditional(props.className, props.className)
            .addConditional(props.hasError, "error")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(props.icon, "has-icon")
            .build();
    }, [
        props.className,
        props.hasError,
        props.isDisabled,
        props.icon,
    ]);

    return (
        <div className={textInputCss}>
            {/* {props.icon ? <div className="code-area-icon">{props.icon}</div> : null} */}
            {/* <textarea
                className="native-input"
                placeholder={props.placeholder}
                disabled={props.isDisabled}
                value={props.value}
                onChange={handleChange}
                onFocus={props.onFocus}
            ></textarea> */}

            <CodeEditor
                className="native-input"
                value={props.value}
                language="js"
                placeholder={props.placeholder}
                onChange={handleChange}
                padding={0}
                style={{
                    fontSize: 14,
                    // backgroundColor: "#000",
                    // fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
        </div>
    );
}
